@import "src/styles/_colors.scss";

.TextInput {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;

  &__input {
    background: $white-10;
    border: 1px $white-40 solid;
  
    border-radius: 6pt;
    padding: 6pt;
  
    color: $text;
    width: 100%;

    font-family: "Open Sans", sans-serif;
    font-weight: 300;
      
    &:hover {
      border-color: $white-60;
    }
  
    &:focus {
      outline: none;
      border-color: $primary-80;
    }
  
    &_empty {
      border-color: $white-20;
    }
  
    &_valid {
      border-color: $success-60;
  
      &:hover {
        border-color: $success-80;
      }
  
      &:focus {
        border-color: $success-100;
      }
    }
  
    &_invalid {
      border-color: $failure-60;
  
      &:hover {
        border-color: $failure-80;
      }
  
      &:focus {
        border-color: $failure-100;
      }
    }
  }

  &__popup {
    position: absolute;
    left: 105%;
    font-size: 10pt;

    width: 6cm;
  }
}